import api from "./api";

export const fetchSpinner = async (walletAddress) => {
    try {
      const response = await api.get("/spinner", {
        params: { wallet_address: walletAddress },
      });
      return response.data;
    } catch (error) {
      console.log("error:", error);
      throw error;
    }
  };

  export const spinner = async (walletAddress) => {
    try {
      const response = await api.post("/spinner/spin", {
        wallet_address: walletAddress,
      });
      return response.data;
    } catch (error) {
      console.log("error:", error);
      throw error;
    }
  };