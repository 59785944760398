import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { fetchQuiz, users } from "../../services/userService";

export const createUser = createAsyncThunk(
  "auth/Users",
  async ({ walletAddress }, { rejectWithValue }) => {
    try {
      const response = await users(walletAddress);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const getQuiz = createAsyncThunk(
  "user/quiz",
  async ({ walletAddress }, { rejectWithValue }) => {
    try {
      const response = await fetchQuiz(walletAddress);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const userSlice = createSlice({
  name: "user",
  initialState: {
    user: null, // Store the user details
    quiz: [],
    isLoading: false,
    quizLoading: false,
    error: null,
    quizError: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(createUser.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(createUser.fulfilled, (state, action) => {
        state.isLoading = false;
        state.user = action.payload;
      })
      .addCase(createUser.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload || "Failed to Create User";
      });

    // Handle fetch user analytics
    builder
      .addCase(getQuiz.pending, (state) => {
        state.quizLoading = true;
        state.quizError = null;
      })
      .addCase(getQuiz.fulfilled, (state, action) => {
        state.quizLoading = false;
        state.quiz = action.payload; // Store analytics data in state
      })
      .addCase(getQuiz.rejected, (state, action) => {
        state.quizLoading = false;
        state.quizError = action.payload || "Failed to fetch user analytics";
      });
  },
});

export default userSlice.reducer;
