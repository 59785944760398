import ReactDOM from "react-dom";
import HomeAwards from "../HomeAwards/HomeAwards";

const RewardsModal = ({ setShowQaRewards }) => {
  function handleClose() {
    setShowQaRewards(false);
  }

  const handleClickInside = (event) => {
    event.stopPropagation();
  };

  const rewardContent = (
    <div
      className="bg-black/70 fixed inset-0 flex justify-center items-center z-50 w-full h-full"
      onClick={handleClose}
    >
      <div
        className="z-30 flex justify-center items-center gradient-border-yellow  drop-shadow-2xl"
        onClick={handleClickInside}
      >
        <HomeAwards setShowQaRewards={setShowQaRewards} />
      </div>
    </div>
  );
  return <>{ReactDOM.createPortal(rewardContent, document.body)}</>;
};

export default RewardsModal;
