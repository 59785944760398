import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
import NavBar from "../../components//NavBar/NavBar";
import NavBarlogo from "../../assets/logo/NavGroupLogo.svg";
import Sidebar from "../Sidebar/Sidebar";
import { MenuToggle } from "./MenuToggle";
import { ethers } from "ethers";
import { useConnectWallet } from "@web3-onboard/react";
import { useDispatch, useSelector } from "react-redux";
import { clearWallet, setWallet } from "../../store/reducer/wallet.slice";

const Header = () => {
  const [{ wallet, connecting }, connect, disconnect] = useConnectWallet();
  const connectHook = useConnectWallet();
  const dispatch = useDispatch();
  const [provider, setProvider] = useState();
  const [isOpen, setIsOpen] = useState(false); // Controls the sidebar state
  // const [address, setAddress] = useState(null);
  const [error, setError] = useState(null);
  const [isConnected, setIsConnected] = useState(false);
  const walletData = useSelector((state) => state.wallet);
  const address = walletData?.wallet?.accounts[0];
  console.log("Current wallet data:", walletData);
  console.log("Add:", address);

  console.log(error);
  console.log("wallet:", wallet);
  console.log("connectHook:>", connectHook);
  // console.log("address->", address);

  // const connectWallet = async () => {
  //   if (!address) {
  //     try {
  //       if (!window.ethereum) {
  //         throw new Error("No crypto wallet found. Please install it.");
  //       }

  //       const provider = new ethers.providers.Web3Provider(window.ethereum);
  //       await provider.send("eth_requestAccounts", []); // Request access to the wallet
  //       const signer = provider.getSigner();
  //       const userAddress = await signer.getAddress();
  //       setAddress(userAddress);
  //       setError(null);
  //     } catch (err) {
  //       setError(err.message);
  //       console.error("Wallet connection failed:", err);
  //     }
  //   } else {
  //     setAddress(null);
  //   }
  // };

  // const checkConnection = async () => {
  //   try {
  //     if (!window.ethereum) {
  //       console.warn("No crypto wallet found. Please install it.");
  //       return;
  //     }

  //     const provider = new ethers.providers.Web3Provider(window.ethereum);
  //     const accounts = await provider.listAccounts();
  //     if (accounts.length > 0 && walletData?.wallet) {
  //       setIsConnected(true);
  //       setAddress(accounts[0]);
  //     } else {
  //       setIsConnected(false);
  //       setAddress(null);
  //     }
  //   } catch (err) {
  //     console.error("Error checking connection:", err);
  //   }
  // };

  const connectWallet = async () => {
    const connectRes = await connect();
    setIsConnected(true);
    console.log("connectRes:", connectRes);

    console.log("Inside connect->", wallet);
  };

  const handleWalletConnect = async () => {
    if (address) {
      await disconnect({ label: wallet?.label || "MetaMask" });
      await dispatch(clearWallet());
      // setAddress(null);
    } else {
      connectWallet();
    }
  };

  useEffect(() => {
    if (wallet?.provider) {
      setProvider(new ethers.providers.Web3Provider(wallet.provider));
    } else {
      setProvider(undefined);
    }
  }, [wallet]);

  // useEffect(() => {
  //   if (walletData.wallet) {
  //     console.log("Checking:", walletData);

  //     checkConnection();
  //   }
  // }, [wallet, isConnected, walletData]);

  useEffect(() => {
    if (wallet) {
      console.log("Hi:", wallet);
      dispatch(setWallet({ wallet: wallet }));
    }
  }, [dispatch, wallet]);

  const walletBtn = address?.address
    ? `${address?.address?.slice(0, 6)}...${address?.address?.slice(-4)}`
    : "Connect Wallet";

  return (
    <div
      className="absolute top-0 left-0 flex justify-between items-center w-full px-4 md:px-6 lg:px-[6.6rem] py-[1rem] lg:py-[2rem] text-black border-b-[1px]  border-[#4D7332] "
      style={{ boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.05)" }}
    >
      <div className="relative flex items-center gap-3  sm:ml-8 lg:ml-0 ">
        {/* Menu Toggle Button */}
        <MenuToggle toggle={() => setIsOpen(!isOpen)} isOpen={isOpen} />

        {/* Sidebar Component */}
        <Sidebar isOpen={isOpen} setIsOpen={setIsOpen} />

        {/* <div className="relative z-20 flex items-center gap-3">
          <div className="w-6 md:w-11">
            <img className="object-contain" src={logo} alt="Logo" />
          </div>
          <div className="border border-black flex items-baseline">
            <p className="font-Luckiest-Guy  self-baseline border lg:text-[2rem] leading-[4rem]">
              HOAXTRUMP
            </p>
          </div>
        </div> */}

        <div className="relative z-20 flex items-center gap-3">
          {/* <div className="w-6 md:w-11 flex items-center justify-center">
            <img className="object-contain" src={logo} alt="Logo" />
          </div>
          <div className=" flex items-center justify-center">
            <p className="font-Luckiest-Guy   self-baseline lg:text-[2rem]">
              HOAXTRUMP
            </p>
          </div> */}
          <img
            src={NavBarlogo}
            className="lg:w-[14.5rem] md:w-[7.5rem] w-[7rem]"
            alt=""
          />
        </div>
      </div>
      <NavBar />
      {/* Wallet Connect Button */}
      <motion.button
        onClick={handleWalletConnect}
        initial="rest"
        whileHover="hover"
        whileTap={{ scale: 0.9 }}
        animate="rest"
        variants={{
          rest: { "--before-width": "0%", scale: 1 },
          hover: { "--before-width": "100%", scale: 1.1 },
        }}
        className="py-[0.4rem]  lg:py-3 px-3 lg:px-6 rounded-[1.4rem] border-[1px] border-[#4D7332] text-[#4D7332] text-[9px] sm:text-base hover:text-white z-10"
        style={{
          transition: "color .2s",
          position: "relative",
          overflow: "hidden",
          zIndex: 20,
        }}
        transition={{ type: "spring", stiffness: 400, damping: 10 }}
        type="button"
      >
        <span
          style={{
            position: "relative",
            zIndex: 2,
          }}
        >
          {walletBtn}
        </span>
        <motion.div
          className="button-overlay"
          style={{
            position: "absolute",
            bottom: 0,
            left: 0,
            width: "var(--before-width)",
            height: "100%",
            backgroundColor: "#4D7332",
            zIndex: 1,
          }}
          variants={{
            rest: { width: "0%" },
            hover: { width: "100%" },
          }}
          transition={{
            duration: 0.3,
            ease: "easeInOut",
          }}
        />
      </motion.button>
    </div>
  );
};

export default Header;
