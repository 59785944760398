import React, { useEffect, useState } from "react";
import maskVector from "../../assets/images/mask-layer.svg";
import mobileMaskVector from "../../assets/images/mobile-mask-layer.svg";
import heroWalpaper from "../../assets/gif/hero-wallpaper.gif";
import questionMarks from "../../assets/images/voteIcon/Frame 2085660293.png";
import VotingBtn from "../SVGs/VotingBtn";
import VotingBtnCircle from "../SVGs/VotingBtnCircle";
import NoSvgBtn from "../SVGs/NoSvgBtn";
import "./VotingSection.css";
// import { useWeb3ModalAccount } from "@web3modal/ethers/react";
import { useDispatch, useSelector } from "react-redux";
import { getQuiz } from "../../store/reducer/users.slice";
import { quizAns } from "../../services/userService";
import { ethers } from "ethers";
import { useConnectWallet } from "@web3-onboard/react";
import QARewardsModal from "../Rewards/QARewardsModal";
const VotingSection = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const [showQaRewards, setShowQaRewards] = useState(false);
  const [rewardsTxt, setRewardTxt] = useState(null);
  const { quiz } = useSelector((state) => state.users);
  const [warning, setWarning] = useState(null);
  const [{ wallet }, connect] = useConnectWallet();
  const walletData = useSelector((state) => state.wallet);
  const address = walletData?.wallet?.accounts[0]?.address;
  const dispatch = useDispatch();
  const connectWallet = async () => {
    await connect();
    setWarning(null);
  };

  useEffect(() => {
    dispatch(getQuiz({ walletAddress: address || null }));
  }, [dispatch, address]);

  async function handleOption(data) {
    try {
      let ansdata = await quizAns({ ...data, walletAddress: address });
      console.log("ans Response:", ansdata);
      setRewardTxt(ansdata?.reward);
      setShowQaRewards(true);
    } catch (error) {
      console.error("failed:", error);
    }
  }

  return (
    quiz?.data?.length > 0 && (
      <>
        <div
          className="h-[20.8rem] w-full z-50 overflow-x-hidden overflow-y-hidden sm:h-[25.8rem] md:h-[30rem] lg:h-[48.5rem] relative flex my-[5rem] 2xl:h-[64rem] justify-center"
          style={{
            backgroundColor: "#fff",
            maskImage: isMobile
              ? `url(${mobileMaskVector})`
              : `url(${maskVector})`,
            WebkitMaskImage: isMobile
              ? `url(${mobileMaskVector})`
              : `url(${maskVector})`,
            maskSize: "cover",
            WebkitMaskSize: "cover",
            maskRepeat: "no-repeat",
            WebkitMaskRepeat: "no-repeat",
            maskPosition: "center",
            WebkitMaskPosition: "center",
          }}
        >
          <div
            className="absolute inset-0"
            style={{
              backgroundImage: `url(${heroWalpaper})`,
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center",
              opacity: 0.5,
            }}
          />
          <div className="flex absolute top-0 left-0 right-0 bottom-0 h-full items-center">
            <div className="h-full w-[6rem] votingSectionLeft font-lato text-center relative">
              <p className="h-full rotate-[270deg] w-[100rem] self-center flex items-center justify-center  text-[#E84539] text-center lg:text-5xl md:text-xl text-xl font-medium md:font-bold leading-normal absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
                Vote & Grab (1000 HXT)
              </p>
            </div>
            <div className="mx-auto">
              {quiz?.data?.map((votingData) => (
                <div
                  key={votingData.id}
                  className="px-[0.5rem]  w-full flex z-50 h-full lg:gap-x-[4.25rem] md:gap-x-[2.5rem] sm:gap-x-[1rem] items-center flex-col sm:flex-row justify-center sm:gap-y-0 gap-y-6 2xl:px-[1rem] 2xl:gap-x-[5.4rem]"
                >
                  <div className="flex z-50 lg:gap-x-[rem] md:gap-x-[2rem] items-center sm:gap-x-[1rem] mt-[2rem] gap-x-[0.2rem] 2xl:gap-x-[5.6rem] justify-end">
                    <p className="  voting-Text text-[#4D7332] text-center font-dk-scrawny 2xl:text-[8.93rem]  lg:text-[5.68rem] sm:text-[3rem] text-[2.5rem] font-normal leading-none sm:tracking-[0.7] tracking-[0.4rem]  w-[19rem] sm:w-[36vw]">
                      {votingData.que_title}
                      {/* <span className="sm:hidden visible">?</span> */}
                    </p>
                    <img
                      src={questionMarks}
                      alt="Question Marks"
                      className="w-[4vw] sm:h-auto h-[3rem] sm:w-[10vw] mt-[2rem] sm:mt-0 hidden sm:block"
                    />
                  </div>
                  <div className="relative flex sm:gap-[2rem] gap-[1rem] items-center  justify-between sm:mb-[0.1rem] mb-[2rem]">
                    <div className="flex flex-col lg:gap-y-[4.8rem] md:gap-y-[1rem] gap-y-[1.5rem] 2xl:gap-y-[6.5rem]">
                      <VotingBtnCircle color="#475C3A" hoverColor="#E84539" />
                      <VotingBtnCircle color="#E84539" hoverColor="#475C3A" />
                    </div>
                    <div className="flex flex-col lg:gap-y-[6.5rem] md:gap-y-[2rem] gap-y-[1.5rem] 2xl:gap-y-[6.5rem]">
                      <VotingBtn
                        hoverColor="#475C3A"
                        handleOption={handleOption}
                        setWarning={setWarning}
                        address={address}
                        que_id={votingData.que_id}
                        ans_id={votingData.answers[1].ans_id}
                      />
                      <NoSvgBtn
                        hoverColor="#E84539"
                        handleOption={handleOption}
                        setWarning={setWarning}
                        address={address}
                        que_id={votingData.que_id}
                        ans_id={votingData.answers[1].ans_id}
                      />
                    </div>
                    {warning && !address && (
                      <p className="absolute w-full -bottom-4 md:-bottom-10 text-center text-[8px] md:text-base gap-1 font-Montserrat font-semibold text-[#DE2C2C] pt-3">
                        {warning}{" "}
                        <span
                          onClick={connectWallet}
                          className="underline cursor-pointer"
                        >
                          Connect?
                        </span>
                      </p>
                    )}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
        {showQaRewards && (
          <QARewardsModal
            setShowQaRewards={setShowQaRewards}
            rewardsTxt={rewardsTxt}
          />
        )}
      </>
    )
  );
};

export default VotingSection;
